import axiosServices from 'auth/FetchInterceptor';


export const endpoints = {
  key: '/users',
  signup: '/signup',
  login: '/login',
  create: '/create',
  update: '/update',
};

export async function signupUser(userData) {
  try {
    const response = await axiosServices.post(endpoints.key + endpoints.signup, userData);
    return { success: true, data: response };
  } catch (error) {
    //console.error('Error during signup:', error);
    return { success: false, error: error };
  }
}

// Function to handle user login
export async function loginUser(userData) {
  try {
    const response = await axiosServices.post(endpoints.key + endpoints.login, userData);
    return { success: true, data: response };
  } catch (error) {
    //console.error('Error during login:', error);
    return { success: false, message: error };
  }
}

// Function to fetch user package
export async function fetchPackage() {
  try {
    const response = await axiosServices.get(endpoints.key + endpoints.package);
    return response.data;
  } catch (error) {
    return { success: false, message: error };
  }
}

export async function updateUser(userData) {
  try {
    const response = await axiosServices.put(endpoints.key + endpoints.update, userData);
    //TODO createOrUpdatePermission()
    return { success: true, data: response };
  } catch (error) {
    //console.error('Error during login:', error);
    return { success: false, error: error };
  }
}
