import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    /* {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    }, */
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login-1')),
    },
    {
        key: 'login-2',
        path: `${AUTH_PREFIX_PATH}/login-2`,
        component: React.lazy(() => import('views/auth-views/authentication/login-2')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register-1')),
    },
    {
        key: 'register-2',
        path: `${AUTH_PREFIX_PATH}/register-2`,
        component: React.lazy(() => import('views/auth-views/authentication/register-2')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'error-page-1',
        path: `${AUTH_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    },
    {
        key: 'error-page-2',
        path: `${AUTH_PREFIX_PATH}/error-page-2`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
    },
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.analytic',
        path: `${APP_PREFIX_PATH}/dashboards/analytic`,
        component: React.lazy(() => import('views/app-views/dashboards/analytic')),
    },
    {
        key: 'dashboard.sales',
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        component: React.lazy(() => import('views/app-views/dashboards/sales')),
    },
    {
        key: 'firmalar',
        path: `${APP_PREFIX_PATH}/firma/ekle`,
        component: React.lazy(() => import('views/app-views/firmalar/firmaekle')),
    },
    {
        key: 'firmalarim',
        path: `${APP_PREFIX_PATH}/firma/listele`,
        component: React.lazy(() => import('views/app-views/firmalar/firmalistele')),
    },
    {
        key: 'araci-ekle',
        path: `${APP_PREFIX_PATH}/araci/ekle`,
        component: React.lazy(() => import('views/app-views/aracilar/araciEkle')),
    },
    {
        key: 'araci-list',
        path: `${APP_PREFIX_PATH}/aracilar/listele`,
        component: React.lazy(() => import('views/app-views/aracilar/aracilarim')),
    },
    {
        key: 'smmm-ekle',
        path: `${APP_PREFIX_PATH}/smmm/ekle`,
        component: React.lazy(() => import('views/app-views/smmm/smmmEkle')),
    },
    {
        key: 'smmm-list',
        path: `${APP_PREFIX_PATH}/smmm/listele`,
        component: React.lazy(() => import('views/app-views/smmm/smmmListe')),
    },
    {
        key: 'cozumortagi-ekle',
        path: `${APP_PREFIX_PATH}/cozumortagi/ekle`,
        component: React.lazy(() => import('views/app-views/cozumortagi/cozumOrtagiEkle')),
    },
    {
        key: 'cozumortagi-list',
        path: `${APP_PREFIX_PATH}/cozumortagi/listele`,
        component: React.lazy(() => import('views/app-views/cozumortagi/cozumOrtaklarim')),
    },
    {
        key: 'istalebi-ekle',
        path: `${APP_PREFIX_PATH}/istalebi/ekle`,
        component: React.lazy(() => import('views/app-views/istalebi/isTalebiEkle')),
    },
    {
        key: 'istalebi-list',
        path: `${APP_PREFIX_PATH}/istalebi/listele`,
        component: React.lazy(() => import('views/app-views/istalebi/isTaleplerim')),
    },
    {
        key: 'edit-profile',
        path: `${APP_PREFIX_PATH}/edit-profile`,
        component: React.lazy(() => import('views/app-views/pages/profile')),
    },
    {
        key: 'setting',
        path: `${APP_PREFIX_PATH}/setting`,
        component: React.lazy(() => import('views/app-views/pages/account')),
    },
    
]