import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import Cookies from 'js-cookie';
import { notification } from 'antd';

const unauthorizedCode = [401, 403]

const axiosServices = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
axiosServices.interceptors.request.use(config => {
	//const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
	const jwtToken = Cookies.get(AUTH_TOKEN) || null;
	
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
	}

  	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Hata'
	})
	return Promise.reject(error)
})

// API respone interceptor
axiosServices.interceptors.response.use( (response) => {
		return response.data
	}, (error) => {

		let notificationParam = {
			message: ''
		}
	
		// Handle unauthorized errors (e.g., 401, 403)
		if (unauthorizedCode.includes(error.response.status)) {
			notificationParam.message = 'Kimlik Doğrulama Başarısız'
			notificationParam.description = 'Lütfen tekrar giriş yapın'
			Cookies.remove(AUTH_TOKEN)
			store.dispatch(signOutSuccess());
			notification.error(notificationParam);
		}

		// Handle 400 Bad Request errors separately
		/*if (error.response.status === 400) {
			// Return the error response directly for application-specific handling
			return Promise.resolve(error.response);
		  }*/

		if (error.response.status === 404) {
			//notificationParam.message = 'Bulunamadı'
		}

		if (error.response.status === 500) {
			notificationParam.message = 'Sunucu Hatası';
			notification.error(notificationParam);
		}
	
		if (error.response.status === 508) {
			notificationParam.message = 'Zaman Aşımı';
			notification.error(notificationParam);
		}

		return Promise.reject(error);
	}
);

export default axiosServices