import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import Cookies from 'js-cookie';
import { notification } from 'antd';
import { loginUser, signupUser } from '../../api/user';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	//token: localStorage.getItem(AUTH_TOKEN) || null
	token: Cookies.get(AUTH_TOKEN) || null,
	user: null,
}

export const signIn = createAsyncThunk('auth/signIn', async (data, { rejectWithValue }) => {
	const { email, password } = data;
	try {
		const response = await FirebaseService.signInEmailRequest(email, password);
		if (response.user) {
			const token = response.user.accessToken;
			const displayName = response.user.displayName || ''; // Handle displayName from Firebase
			const [name, surname] = displayName.split(' '); // Split the displayName if present

			Cookies.set(AUTH_TOKEN, token, { secure: true, sameSite: 'strict' });
			const loginResult = await loginUser({ email });

			if (loginResult.success && loginResult.data) {
				notification.success({ message: 'Giriş Başarılı', description: 'Yönlendiriliyorsunuz...' });
				// Set the user object with fallback for name/surname
				const user = {
					...loginResult.data.data.user,
					name: name || loginResult.data.data.user.name || 'Unknown',
					surname: surname || loginResult.data.data.user.surname || 'Unknown',
				};
				return { token, user };
			} else {
				notification.error({ message: 'Giriş Başarısız', description: 'Giriş esnasında hata oluştu' });
				return rejectWithValue('Giriş Başarısız');
			}
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Hata');
	}
})
export const signUp = createAsyncThunk('auth/signUp', async (data, { rejectWithValue }) => {
	const { email, password, companyRole } = data
	try {
		const response = await FirebaseService.signUpEmailRequest(email, password)
		if (response.user) {
			const token = response.user.refreshToken;

			const registerData = {
				id: response.user.uid,
				...data
			};
			Cookies.set(AUTH_TOKEN, token, { secure: true, sameSite: 'strict' });

			const createUserResult = await signupUser(registerData);

			if (!createUserResult.success) {
				if (createUserResult?.error?.request?.response) {
					try {
						const errorResponse = JSON.parse(createUserResult.error.request.response);
						const errorMessage = Object.values(errorResponse).flat().join('. ')
						notification.error({ message: 'Kayıt Başarısız', description: errorMessage });
					} catch (jsonError) {
						notification.error({ message: 'Kayıt Başarısız', description: 'Unexpected error format.' });
					}
				} else {
					notification.error({ message: 'Kayıt Başarısız', description: 'No error response from server.' });
				}

				try {
					await FirebaseService.deleteUserRequest(response.user);
					notification.success({ message: 'Kullanıcı Silme Başarılı', description: 'Kullanıcı başarıyla silindi.' });
				} catch (deleteError) {
					notification.error({ message: 'Kullanıcı Silerken Hata', description: "deleteError.message" });
				}
				Cookies.remove(AUTH_TOKEN);
				return rejectWithValue('Kayıt Başarısız');
			}
			else {
				notification.success({ message: 'Kayıt Başarılı', description: 'Yönlendiriliyorsunuz... Lütfen Giriş Yapınız', });
				return { token, user: createUserResult.data };
			}
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		notification.error({ message: 'Hata', description: err.message || 'Hata' });
		return rejectWithValue(err.message || 'Hata')
	}
})

export const signOut = createAsyncThunk('auth/signOut', async () => {
	const response = await FirebaseService.signOutRequest()
	//localStorage.removeItem(AUTH_TOKEN);
	Cookies.remove(AUTH_TOKEN);
	return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
	try {
		const response = await FirebaseService.signInGoogleRequest();
		if (response.user) {
			const token = response.user.accessToken;
			const email = response.user.email;

			Cookies.set(AUTH_TOKEN, token, { secure: true, sameSite: 'strict' });
			const loginResult = await loginUser({ email: email });

			if (loginResult.success && loginResult.data) {
				notification.success({ message: 'Google ile Giriş Başarılı', description: 'Yönlendiriliyorsunuz...' });
				// Return token and user data
				return { token, user: loginResult.data.data.user };
			} else {
				// Handle login failure
				//notification.error({ message: 'Google ile Giriş Başarısız', description: 'Giriş esnasında hata oluştu' });
				localStorage.removeItem("persist:root");
				Cookies.remove(AUTH_TOKEN);
				return rejectWithValue('Giriş Başarısız');
			}
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Hata');
	}
})

export const signUpWithGoogle = createAsyncThunk('auth/signUpWithGoogle', async (_, { rejectWithValue }) => {
	try {
		const response = await FirebaseService.signInGoogleRequest();

		if (response.user) {
			const email = response.user.email;
			const displayName = response.user.displayName || email;
			const [name, surname] = displayName.split(' ');
			const uid = response.user.uid;
			const token = response.user.accessToken;

			Cookies.set(AUTH_TOKEN, token, { secure: true, sameSite: 'strict' });

			const registerData = {
				id: uid,
				email: email,
				name: name ,
				surname: surname ,
				role: 'ADMIN',
				companyRole: 'CONSULTING',
			};

			const createUserResult = await signupUser(registerData);

			if (createUserResult.success) {
				notification.success({
					message: 'Google ile Kayıt Başarılı',
					description: 'Yönlendiriliyorsunuz... Lütfen Giriş Yapınız',
				});
				
				return { token, user: createUserResult.data };
			} else {
				Cookies.remove(AUTH_TOKEN);
				localStorage.removeItem("persist:root");
				notification.error({
					message: JSON.parse(createUserResult?.error?.request?.response).error || 'Unknown error',
					description: 'Kayıt esnasında hata oluştu',
				});
				return rejectWithValue('Backend registration failed');
			}
		} else {
			return rejectWithValue('Google authentication failed');
		}
	} catch (err) {
		notification.error({
			message: 'Hata',
			description: err.message || 'Google ile Kayıt Başarısız',
		});
		return rejectWithValue(err.message || 'Hata');
	}
});


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
			state.user = action.payload.user;
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
			state.loading = false;
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.user = null;
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		hideLoading: (state) => {
			state.loading = false;
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		},
		userReducer: (state, action) => {
			state.user = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false;
				state.token = action.payload.token;
				state.user = {
					...action.payload.user,
					name: action.payload.user.name || 'Unknown',   // Fallback for name
					surname: action.payload.user.surname || 'Unknown', // Fallback for surname
				};
				state.redirect = '/';
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.user = null;
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.user = null;
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
				state.user = action.payload.user;
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signUpWithGoogle.rejected, (state, action) => {
				state.loading = false;
				state.message = action.payload;  // Show the error message
				state.showMessage = true;         // Display the error message in the UI
			})			
			.addCase(signUpWithGoogle.pending, (state) => {
				state.loading = true;
			})
			.addCase(signUpWithGoogle.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/';  // Add this line to handle redirection after sign-up
			})	
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false;
				state.token = action.payload.token;
				state.user = {
					...action.payload.user,
					name: action.payload.user.name ,   // Fallback for name
					surname: action.payload.user.surname , // Fallback for surname
				};
				state.redirect = '/';
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
					
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	hideLoading,
	signInSuccess,
	userReducer
} = authSlice.actions

export default authSlice.reducer