import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter  } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
import './lang'
import Cookies from 'js-cookie';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { auth } from 'auth/FirebaseAuth';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        Cookies.set(AUTH_TOKEN, token);
      } else {
        Cookies.remove(AUTH_TOKEN);
      }
    });
    return () => unsubscribe(); // Cleanup on unmount
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter history={history}>
            <ThemeSwitcherProvider 
              themeMap={themes} 
              defaultTheme={THEME_CONFIG.currentTheme} 
              insertionPoint="styles-insertion-point"
            >
              <Layouts />
            </ThemeSwitcherProvider>
          </BrowserRouter>  
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
