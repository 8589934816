const FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

/*const FirebaseConfig = {  //emilus working firebase account
  apiKey: 'AIzaSyAOWoRQGoegkmwnCca7B2GG5GUG_ZP1lcM',
  authDomain: 'emilus.firebaseapp.com',
  databaseURL: 'https://emilus.firebaseio.com',
  projectId: 'emilus',
  storageBucket: 'emilus.appspot.com',
  messagingSenderId: '807555350717',
  appId: '1:807555350717:web:145ba7c21af47203a2f7d4',
  measurementId: 'G-8KE7HJB252'
};*/

export default FirebaseConfig

