import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

const middlewares = [];

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth', 'theme'], // You can add other slices you want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer());

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
			  ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === 'development',
})

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(persistReducer(persistConfig, rootReducer(store.asyncReducers)));
	return store
}

export const persistor = persistStore(store);

export default store